import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import { Card, Row, Col, Modal, Button } from "react-bootstrap"
import DataTable from "react-data-table-component";
import endpoint from "../../context/endpoint";
import { Context } from "../../context/Context";
import moment from 'moment';
import { ErrorAlert, SuccessAlert } from "../Toast/toast";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../Loader/loader";
import { log } from "nvd3";
import { useForm } from "react-hook-form";

export const CreateTimeVariation = () => {

  const { register,
		formState: { errors },
	} = useForm();

  const { user } = useContext(Context);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  
  const variableList = [
    {id: 1, label: "Retirement", value: "Retirement"},
    {id: 2, label: "Increment", value: "Increment"},
    {id: 3, label: "Promotion", value: "Promotion"},
    {id: 4, label: "Confirmation", value: "Confirmation"}
  ]

  const periodList = [
    {id: 1, label: "Days", value: "Days"},
    {id: 2, label: "Months", value: "Months"},
    {id: 3, label: "Years", value: "Years"}
  ]

  const durationList = [
    {id: 1, label: "1", value: "1"},{id: 2, label: "2", value: "2"},{id: 3, label: "3", value: "3"},
    {id: 4, label: "4", value: "4"},{id: 5, label: "5", value: "5"},{id: 6, label: "6", value: "6"},
    {id: 7, label: "7", value: "7"},{id: 8, label: "8", value: "8"},{id: 9, label: "9", value: "9"},
    {id: 10, label: "10", value: "10"},{id: 11, label: "11", value: "11"},{id: 12, label: "12", value: "12"},
    {id: 13, label: "13", value: "13"},{id: 14, label: "14", value: "14"},{id: 15, label: "15", value: "15"},
    {id: 16, label: "16", value: "16"},{id: 17, label: "17", value: "17"},{id: 18, label: "18", value: "18"},
    {id: 19, label: "19", value: "19"},{id: 20, label: "20", value: "20"},{id: 21, label: "21", value: "21"},
    {id: 22, label: "22", value: "22"},{id: 23, label: "23", value: "23"},{id: 24, label: "24", value: "24"},
    {id: 25, label: "25", value: "25"},{id: 26, label: "26", value: "26"},{id: 27, label: "27", value: "27"},
    {id: 28, label: "28", value: "28"},{id: 29, label: "29", value: "29"},{id: 30, label: "30", value: "30"},
  ]

  const [variation, setTimeVariation] = useState({
    name: '',
    period: '',
    duration: '',
  });

  const [newTimeVariation, setNewTimeVariation] = useState({
    variation_id:'', name:'', period:'', duration: ''
  })

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [idToDelete, setIdToDelete] = useState('')

  useEffect(() => {
    getAllData();
  }, []);


  const getAllData = async () => {
    await endpoint.get(`/time-variable/list`)
      .then((res) => {
        // console.log("all time variations", res.data.data)
        setData(res.data.data)
      })
      .catch((err) => {
        // console.log(err)
      })
  } 

  const handlePageChange = page => {
    setPage(page);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);

  }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
   await endpoint.post(`/time-variable/create`, variation).then(res => {
        // console.log(res)
        setTimeVariation({ ...variation, name:" ", period:" ", duration: " "})
        getAllData()
        SuccessAlert(res.data.message);
        setLoading(false);

    }).catch((err) => {
        setLoading(false);
        ErrorAlert(err.response.data.message)
        // console.log(err)
    });
}


  const onEdit = (row) => {
    // console.log("time variation to edit", row.id)
    setNewTimeVariation({...newTimeVariation, variation_id:row.id, name:row.name, period:row.period, duration:row.duration})
    setOpen(true);
    // console.log("time variation to update", newTimeVariation)
  }

  const handleEdit = async () => {
    // console.log("time variation id to update", newTimeVariation.variation_id)
    setLoading(true)
    // console.log("my updating data", newTimeVariation)
    await endpoint.put(`time-variable/edit/${newTimeVariation.variation_id}`, newTimeVariation).then((res) => {
      // console.log(res.data);
      getAllData()
      setLoading(false)
      setOpen(false);
      SuccessAlert(res.data.message)

    }).catch((err) => {
      setLoading(false)
      ErrorAlert(err.response.data.message)
      // console.log(err)
    })
  }


  const onDelete = (row) => {
    // console.log("time-variation to delete", row.id)
    setOpen(false);
    setIdToDelete(row.id);
    setDeleteOpen(true);
  }

  const handleDelete = async (e) => {
    // console.log("time-variation2 to delete", idToDelete)
    e.preventDefault()
    await endpoint.delete(`/time-variable/delete/${idToDelete}`).then((res) => {
          // console.log(res.data)
          SuccessAlert(res.data.message)
          getAllData()
          setLoading(false)
          setDeleteOpen(false);
    }).catch((err) => {
      ErrorAlert(err.response.data.message)
      // console.log(err)
    })
  }

  const reset = () => {
    // setRole("");
    setId("")
  }

  const onClose = () => {
    reset();
    setOpen(false);
    setDeleteOpen(false);
  }

  const columns = [
    {
      name: "#",

      cell: (row, index) => (index + 1) + ((page - 1) * perPage),
      width: "8%"
    },

    {
      name: "Name",
      selector: (row) => [row.name],
      sortable: true,
      width: "30%",
      cell: (row) => (
        <h6 className="fs-12 fw-semibold">{row.name}</h6>
      ),
    },
    {
      name: "Period",
      selector: (row) => [row.period],
      sortable: true,
      width: "30%",
      cell: (row) => (
        <h6 className="fs-12 fw-semibold">{row.period}</h6>
      ),
    },
    {
      name: "Duration",
      selector: (row) => [row.duration],
      sortable: true,
      width: "20%",
      cell: (row) => (
        <h6 className="fs-12 fw-semibold">{row.duration}</h6>
      ),
    },
    {
      name: "Action",
      cell: (row) => (<Row > <Col xs={4} style={{ paddingRight: "0px", paddingLeft: "0px" }}><button className="btn btn-sm btn-secondary" 
      onClick={(e) => { onEdit(row) }} 
      variant="secondary" title="Action" size="sm">Edit</button>

      </Col><Col xs={4}>
        <button className="btn btn-sm btn-danger" 
            onClick={(e) => { onDelete(row) }} 
            variant="danger" title="Action" size="sm"
          >Delete
        </button>
      </Col>
      </Row>)
    },

  ];

  return (
    <>
      {load ?

        <Loader /> :

        <div>
          <div id="page-wrapper" className="box box-default">
            <div className="container-fluid">
              <div className="col-md-12 text-success">
              </div>
              <br />
              <hr />
              <Row className="row">
                {/* <Col xs={2} md={2}></Col> */}
                <Col xs={12} md={12} > <br />

                  <Card >

                    <Card.Body>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <Row>
                    <Col lg={4} md={12}>
                    <div className="form-group">
                      <label htmlFor="exampleInputname">Variable Name</label>
                      <select className="form-control" required style={{ width: '100%', marginTop: '0px' }}
                      {...register("name")}
                      onChange={(event) =>
                          setTimeVariation({ ...variation, name: event.target.value })
                        }
                        value={variation.name}
                      >
                        <option value="">---Select---</option>
                        {variableList.map(variable => (<option key={variable.id} value={variable.value}>{variable.label}</option>))}
                      </select>
                    </div>
                    </Col>
                    <Col lg={4} md={12}>
                      <div className="form-group">
                      <label htmlFor="exampleInputname">Period</label>
                      <select className="form-control" required style={{ width: '100%', marginTop: '0px' }}
                      {...register("period")}
                      onChange={(event) =>
                          setTimeVariation({ ...variation, period: event.target.value })
                        }
                        value={variation.period}
                      >
                        <option value="">---Select---</option>
                        {periodList.map(period => (<option key={period.id} value={period.value}>{period.label}</option>))}
                      </select>
                      </div>
                    </Col>
                    <Col lg={4} md={12}>
                      <div className="form-group">
                      <label htmlFor="exampleInputname">Duration</label>
                      <select className="form-control" required style={{ width: '100%', marginTop: '0px' }}
                      {...register("duration")}
                      onChange={(event) =>
                          setTimeVariation({ ...variation, duration: event.target.value })
                        }
                        value={variation.duration}
                      >
                        <option value="">---Select---</option>
                        {durationList.map(duration => (<option key={duration.id} value={duration.value}>{duration.label}</option>))}
                      </select>
                          
                      </div>
                    </Col>
                    </Row>
                    <Row>
                    <div className="form-group">
                      <div className="col-sm-offset-2 text-center col-sm-9">
                        <button className={isLoading ? "btn btn-success pull-right btn-loading" : "btn btn-success pull-right"} disabled={isLoading} 
                        onClick={handleSubmit}
                        >Add Time Variation</button>
                      </div>
                    </div>
                    </Row>
                  </form>
                    </Card.Body>

                  </Card>
                  
                </Col>
                <Col xs={3} md={4}></Col>
              </Row>
            </div>
          </div>
          <Card >
            <Card.Body >
              <h3 className="text-center">Time Variation List</h3>
              <Row className="row">
                <Col md={12} className="col-md-12">
                  <DataTable
                    //  fixedHeader
                    columns={columns}
                    // selectableRows
                    data={data}
                    // customStyles={customStyles}
                    // persistTableHead
                    defaultSortField="id"
                    defaultSortAsc={false}
                    striped={true}
                    center={true}
                    pagination
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}

                    paginationPerPage={perPage}
                    highlightOnHover
                  />

                  <Modal show={open} >
                    <Modal.Body className="text-center p-4">
                      <DialogTitle>Edit Time Variation
                        <Button
                          onClick={onClose}
                          className="btn-close"
                          variant=""
                          disabled={isLoading}
                        >
                          x
                        </Button>
                      </DialogTitle>
                      <DialogContent>

                        <Row className="row">
                          <Col> <br />

                            <Card>

                              <Card.Body>
                                <form className="form-horizontal">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputname">Variable Name</label>
                                  <select className="form-control" required style={{ width: '100%', marginTop: '0px' }}
                                  // {...register("name")}
                                  onChange={(event) =>
                                      setNewTimeVariation({ ...newTimeVariation, name: event.target.value })
                                    }
                                    value={newTimeVariation.name}
                                  >
                                    <option value="">---Select---</option>
                                    {variableList.map(variable => (<option key={variable.id} value={variable.value}>{variable.label}</option>))}
                                  </select>
                                  </div>

                                  <div className="form-group">
                                    <label className="col-md-6  cecontrol-label">Period</label>
                                    <select className="form-control" required style={{ width: '100%', marginTop: '0px' }}
                                      // {...register("period")}
                                      onChange={(event) =>
                                        setNewTimeVariation({ ...newTimeVariation, period: event.target.value })
                                        }
                                        value={newTimeVariation.period}
                                      >
                                        <option value="">---Select---</option>
                                        {periodList.map(period => (<option key={period.id} value={period.value}>{period.label}</option>))}
                                      </select>
                                  </div>

                                  <div className="form-group">
                                    <label className="col-md-6  cecontrol-label">Duration</label>
                                    <select className="form-control" required style={{ width: '100%', marginTop: '0px' }}
                                      // {...register("duration")}
                                      onChange={(event) =>
                                        setNewTimeVariation({ ...newTimeVariation, duration: event.target.value })
                                        }
                                        value={newTimeVariation.duration}
                                      >
                                        <option value="">---Select---</option>
                                        {durationList.map(duration => (<option key={duration.id} value={duration.value}>{duration.label}</option>))}
                                      </select>
                                  </div>
                                </form>

                              </Card.Body>

                            </Card>
                          </Col>
                          <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center', marginLeft: "60px" }}>
                              <Button onClick={onClose} disabled={isLoading} variant="danger" className="me-1">Cancel</Button>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center', marginLeft: "60px" }}>
                              <Button 
                              onClick={handleEdit}
                               disabled={isLoading} variant="success" className={isLoading ? "me-1  btn-loading" : "me-1"}> {isLoading ? "Save" : "Save"}</Button>
                            </Col>
                          </Row>
                        </Row>

                      </DialogContent>
                      <DialogActions>

                      </DialogActions>
                      {/* </Dialog> */}
                    </Modal.Body>

                  </Modal>
                          
                  <Modal show={deleteOpen}>
                    <Modal.Body className="text-center p-4">
                      <DialogTitle>Delete Time Variation
                        <Button
                          onClick={onClose}
                          className="btn-close"
                          variant=""
                        >
                          x
                        </Button>
                      </DialogTitle>
                      <DialogContent>


                        <div>

                          <div className="modal-body">
                            <p>Do you really want to delete <span className="fw-bold"></span> time variation <br /> This process cannot be undone.</p>
                          </div>

                          <Row>
                            <Col xs={5} md={5} align="right">
                              <button type="button" className="btn btn-sm btn-secondary" 
                              onClick={onClose}
                              >Cancel</button>
                            </Col>
                            <Col xs={1} md={1}  ></Col>
                            <Col xs={5} md={5} align="left">
                              <button 
                              onClick={handleDelete}
                               className="btn btn-sm btn-danger">Yes, Delete </button>
                            </Col>
                          </Row>

                        </div>

                      </DialogContent>
                    </Modal.Body>
                  </Modal>
                 
                </Col>
              </Row>
              {/* <!-- /.col -->  */}

            </Card.Body>
          </Card>




        </div>
      }
    </>
  )
}