import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Badge, Card, Col, Modal, Button } from "react-bootstrap";
import endpoint from "../../context/endpoint";
import Loader from "../Loader/loader";

export default function LeaveApplicationApproval({ year }) {
    const [data, setRoasterList] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [declineRoaster, setDeclineRoaster] = useState(false);
    const [approveRoaster, setApproveRoaster] = useState('')

    const handleShowApproveModal = () => {
        setShowApproveModal(true)
    }

    const handleShowDeclineModal = () => {
        setDeclineRoaster(true)
    }

    useEffect(() => {
        leaveApplicationList()
    }, [year])

    const leaveApplicationList = async () => {
        setLoading(true)
        await endpoint.post(`/leave-application/auth-department-head`, {year: year})
            .then(({ data }) => {
                console.log("application list", data.data)
                // return
                setRoasterList(data.data)
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const approveLeaveRoaster = async (e, id) => {
        e.preventDefault();
        // console.log("id to approve", id)
        setLoading(true)
        await endpoint.post(`/leave-application/approve-application/${id}`)
            .then((res) => {
                console.log(res)
                leaveApplicationList()
                setShowApproveModal(false)
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const declineLeaveRoaster = async (e, id) => {
        e.preventDefault();
        setLoading(true)
        await endpoint.post(`/leave-application/decline-application/${id}`)
            .then((res) => {
                // console.log(res)
                leaveApplicationList()
                setDeclineRoaster(false)
                setLoading(false)
            }).catch((err) => {
                // console.log(err)
                setLoading(false)
            })
    }

    const columns = [
        {
            name: "#",
            cell: (row, index) => (index + 1),
            width: "65px",
        },
        {
            name: "Full Name",
            selector: (row) => [row.Profile.first_name],

            style: { textAlign: 'right' },
            sortable: true,

            width: "180px",
            cell: (row) =>
                <div className="fs-12 fw-bold ">{row.Profile.first_name !== null ? (row.Profile.first_name).toUpperCase() : ""} {row.Profile.last_name !== null ? (row.Profile.last_name).toUpperCase() : ""} {row.Profile.other_name !== null ? (row.Profile.other_name).toUpperCase() : ""}</div>
            ,
        },
        {
            name: <div>No. of Days</div>,
            selector: (row) => [row.no_of_leave_days],

            style: { textAlign: 'right' },
            sortable: true,

            width: "90px",
            cell: (row) =>
                <div className="fs-12 fw-bold ">{row.no_of_leave_days !== null ? (row.no_of_leave_days) : ""}</div>
            ,
        },
        {
            name: "Start Date",
            selector: (row) => [row.start_date],

            style: { textAlign: 'right' },
            sortable: true,

            width: "120px",
            cell: (row) =>
                <div className="fs-12 fw-bold ">{row.start_date !== null ? (row.start_date) : ""}</div>
            ,
        },
        {
            name: "End Date",
            selector: (row) => [row.end_date],

            style: { textAlign: 'right' },
            sortable: true,

            width: "120px",
            cell: (row) =>
                <div className="fs-12 fw-bold ">{row.end_date !== null ? (row.end_date) : ""}</div>
            ,
        },
        {
            name: "Status",
            selector: (row) => [row.approval_stage],

            style: { textAlign: 'right' },
            sortable: true,

            width: "120px",
            cell: (row) =>
                <div className="fs-12 fw-bold ">
                    <Badge bg={row.approval_stage == 1 ? `green` : `orange`} className="badge me-1 mb-1 mt-1">
                        {row.approval_stage == 1 ? `Approved` : ''}
                        {row.approval_stage == 0 ? `Awaiting Approval` : ''}
                        {row.approval_stage == 2 ? `Declined` : ''}
                    </Badge>
                </div>
            ,
        },
        {
            name: "Actions",
            selector: (row) => [row.id],

            // style: { textAlign: 'right' },
            sortable: true,

            width: "180px",
            cell: (row) =>
                <div className="fs-12 fw-bold ">
                    {
                        (row.approval_stage == 0 || row.approval_stage == 2) && ( <>
                            <Button onClick={() => {
                                handleShowApproveModal()
                                setApproveRoaster(row)
                            }} type="button" className="btn btn-primary btn-sm m-1" variant="info"> Approve <span className="fe fe-arrow-right"> </span> </Button>

                        </> )
                    }
                    {
                        (row.approval_stage == 0) && ( <>
                            <Button onClick={() => {
                                handleShowDeclineModal()
                                setDeclineRoaster(row)
                            }} type="button" className="btn btn-danger btn-sm m-1" variant="danger"> Decline <span className="fe fe-cancel"> </span> </Button>
                        </> )
                    }

                </div>
            ,
        }

    ]

    const tableDatas = { columns, data };

    return (
        <>
            {
                <DataTableExtensions {...tableDatas}>
                    {isLoading ? <Loader />
                        : <DataTable
                            fixedHeader
                            columns={columns}
                            // selectableRows
                            data={data}
                            // customStyles={customStyles}
                            persistTableHead
                            defaultSortField="id"
                            defaultSortAsc={false}
                            striped={true}
                            center={true}
                            pagination
                            paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangePage={handlePageChange}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                            // onChangeRowsPerPage(currentRowsPerPage, currentPage)
                            // paginationPerPage={perPage}
                            highlightOnHover
                        />
                    }

                </DataTableExtensions>
            }

            <Modal show={showApproveModal} >
                <Modal.Header >
                    <Button
                        onClick={() => setShowApproveModal(false)}
                        className="btn-close"
                        variant=""
                    >
                        x
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Card>

                            <Card.Header>
                                <Card.Title as="h3">Approve Leave Application</Card.Title>

                            </Card.Header>
                            <Card.Body>
                                <Col lg={12} md={12}>
                                    Please confirm you want to approve this leave application
                                    <p>Leave Start date: {approveRoaster.start_date} </p>
                                    <p>Leave End date: {approveRoaster.end_date}</p>
                                </Col>

                            </Card.Body>

                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" className="me-1" onClick={() => setShowApproveModal(false)}>
                        Close
                    </Button>
                    <Button type="submit" onClick={(e) => approveLeaveRoaster(e, approveRoaster.id)} variant="primary" className="me-1">
                        Approve
                    </Button>

                </Modal.Footer>


            </Modal>

            <Modal show={declineRoaster} >
                <Modal.Header >
                    <Button
                        onClick={() => setDeclineRoaster(false)}
                        className="btn-close"
                        variant=""
                    >
                        x
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Card>

                            <Card.Header>
                                <Card.Title as="h3">Decline Leave Application</Card.Title>

                            </Card.Header>
                            <Card.Body>
                                <Col lg={12} md={12}>
                                    Please confirm you want to decline this leave application
                                    <p>Leave Start date: {declineRoaster.start_date} </p>
                                    <p>Leave End date: {declineRoaster.end_date}</p>
                                </Col>

                            </Card.Body>

                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="orange" className="me-1" onClick={() => setDeclineRoaster(false)}>
                        Close
                    </Button>
                    <Button type="submit" onClick={(e) => declineLeaveRoaster(e, declineRoaster.id)} variant="primary" className="me-1">
                        Decline
                    </Button>

                </Modal.Footer>


            </Modal>

        </>
    )
}