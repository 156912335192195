import React, { useState, useEffect } from 'react'
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import endpoint from "../../context/endpoint";
import { Context } from "../../context/Context";
import { CForm } from "@coreui/react";
import { DropdownButton, ButtonGroup, Card, Button, Row, Col, InputGroup, Dropdown, Modal, FormGroup, Form, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import Loader from "../Loader/loader";

const AdviceVariation = () => {
    const [isLoading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [showAdd, setAdd] = useState(false);
    const [designations, setDesignations] = useState([])

    useEffect(() => {
        getDesignations()
    }, [])

    const getDesignations = async () => {
        await endpoint.get(`/designation/show`)
            .then((res) => {
                // console.log("desig", res.data.data)
                setDesignations(res.data.data)
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    const columns = [
        {
            name: "S/N",
            cell: (row, index) => (index + 1),
            width: "65px",
        },
        {
            name: "NAME.",
            selector: (row) => [row.fileno],

            style: { textAlign: 'right' },
            sortable: true,

            width: "120px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">NJC/P/0987</div>
            // ,
        },
        {
            name: "RANK",
            selector: (row) => [row.fullname],

            style: { textAlign: 'right' },
            sortable: true,

            width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: "FILE NO",
            selector: (row) => [row.grade],

            style: { textAlign: 'right' },
            sortable: true,

            width: "100px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: "NEW SALARY PER ANNUM",
            selector: (row) => [row.step],

            style: { textAlign: 'right' },
            sortable: true,

            width: "100px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: "AMOUNT OF VARIATION",
            selector: (row) => [row.date_of_appointment],

            style: { textAlign: 'center' },
            // sortable: true,

            width: "150px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
            name: "REASON FOR VARIATION",
            selector: (row) => [row.date_of_first_appointment],

            style: { textAlign: 'center' },
            // sortable: true,

            width: "150px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
            name: "EFFECTIVE DATE",
            selector: (row) => [row.last_increment],

            style: { textAlign: 'center' },
            // sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
            name: "AUTHORITY FOR NOTIFICATION",
            selector: (row) => [row.due_for_increment],

            style: { textAlign: 'center' },
            sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
            name: "WHETHER U.D.C LOAN",
            selector: (row) => [row.location],

            style: { textAlign: 'center' },
            sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
          name: "RANK",
          selector: (row) => [row.location],

          style: { textAlign: 'center' },
          sortable: true,

          // width: "200px",
          // cell: (row) =>
          //     <div className="fs-12 fw-bold ">1/2/2012</div>
          // ,
      },

       

    ]

    const data = [
        {
            id: 1,
            fileno: "NJC/P/0987",
            fullname: "Mr. ABIDJAN JUACHIN Moj",
            grade: 15,
            step: 8,
            date_of_appointment: "1/2/2012",
            date_of_first_appointment: "1/2/2012",
            last_increment: "1/2/2021",
            due_for_increment: "1/2/2023",
            location: "Head Variation"
        },
        {
            id: 1,
            fileno: "NJC/P/1027",
            fullname: "Mr. AHMNED ALMANI JIFFA",
            grade: 13,
            step: 5,
            date_of_appointment: "1/2/2014",
            date_of_first_appointment: "1/2/2014",
            last_increment: "1/2/2021",
            due_for_increment: "1/2/2023",
            location: "Head Variation"
        }
    ];

    const tableDatas = {
        columns,
        data,
    };

    return (
        <>
            {
                <DataTableExtensions {...tableDatas}>
                    {isLoading ? <Loader />
                        : <DataTable
                            fixedHeader
                            columns={columns}
                            // selectableRows
                            data={data}
                            // customStyles={customStyles}
                            persistTableHead
                            defaultSortField="id"
                            defaultSortAsc={false}
                            striped={true}
                            center={true}
                            pagination
                            paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangePage={handlePageChange}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                            // onChangeRowsPerPage(currentRowsPerPage, currentPage)
                            // paginationPerPage={perPage}
                            highlightOnHover
                        />
                    }

                </DataTableExtensions>
            }

            {/* modal to confirm staff */}
            <Modal show={showAdd} >
                <Modal.Header >
                    <Button
                        onClick={() => setAdd(false)}
                        className="btn-close"
                        variant=""
                    >
                        x
                    </Button>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h3"> Forward for Approval </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Col lg={12} md={12}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputname1">Select Cadre</label>
                                            <select className="form-control"
                                                required
                                                // value={personalInfo.designation_id}
                                                // onChange={(e) => setPersonalInfo({ ...personalInfo, designation_id: e.target.value })}
                                            >
                                                <option value=""> Select... </option>
                                                {designations.map(designation => (<option key={designation.id} value={designation.id}>{designation.name}</option>))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        <FormGroup>
                                            <label htmlFor="exampleInputname">Comment</label>
                                            <textarea
                                                className="form-control is-invalid"
                                                id="validationTextarea"
                                                placeholder="Required example textarea"
                                                required
                                            ></textarea>
                                        </FormGroup>
                                    </Col>

                                </Card.Body>
                            </Card>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className="me-1" onClick={() => setAdd(false)}>
                            Close
                        </Button>
                        <Button type="button" variant="success" onClick={() => setAdd(false)} className="me-1">
                            <span className="fe fe-arrow-up"> Forward </span>
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* end modal to confirm staff*/}

        </>
    )
}

export default AdviceVariation