import React, { useState } from "react"
import { Breadcrumb, Col, Row, Card, Button, FormGroup, Form } from "react-bootstrap";
import { default as UnitHeadLeaveRoasterApproval } from "../../../data/Leave/LeaveRoasterApproval"

export default function LeaveRoasterApproval() {

    const year = new Date().getFullYear();
    const years = Array.from(new Array(5), (val, index) => year - index);

    const [roasterYear, setYear] = useState(year)

    return (
        <div>
            <div className="page-header ">
                <div>
                    <h1 className="page-title">LEAVE </h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Leave roaster approval
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>

            <Row>
                <Col sm={12} className="col-12">
                    <Card>
                        <Card.Header>
                            <Col className="card-title text-center mb-0"> Leave Roaster Report </Col>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={2}></Col>
                                <Col sm={12} md={8}>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputname1">Select Year</label>
                                        <select className="form-control"
                                            onChange={(e) => setYear(e.target.value)}
                                            value={roasterYear}
                                        >
                                            {years.map(year => (<option>{year}</option>))}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={2}></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm={12} className="col-12">
                    <Card>
                        <Card.Header>
                            <Col className="card-title text-center mb-0"> LEAVE ROASTER </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className="">
                                <div className="">
                                    <UnitHeadLeaveRoasterApproval year={roasterYear} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}