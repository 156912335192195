import React from 'react'
import { Breadcrumb, Col, Row, Card, Button } from "react-bootstrap";
import StaffDueForConfirmation from '../../../data/Variation/StaffDueForConfirmation';

const StaffConfirmation = () => {
  return (
    <div>
      <div className="page-header ">
        <div>
          <h1 className="page-title">Staff Confirmation </h1>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item" href="#">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item active breadcrumds"
              aria-current="page"
            >
               Staff Confirmation
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
       
      </div>
      
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
                <Col className="card-title text-center mb-0"> STAFF DUE FOR CONFIRMATION </Col>
            </Card.Header>
            <Card.Body>
              <div className="">
                <div className="">
                    <StaffDueForConfirmation />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StaffConfirmation