import React, { useState } from 'react'
import { Breadcrumb, Col, Row, Card, Button, FormGroup, Form } from "react-bootstrap";
import StaffDueForRetirement from '../../../data/Retirement/StaffDueForRetirement';

const RetirementAlert = () => {
    const [dueDate, setDueDate] = useState('')


    return (
        <div>
            <div className="page-header ">
                <div>
                    <h1 className="page-title"> Staff Due For Retirement  </h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Staff Due For Retirement
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>

            <Card>
                <Card.Body>

                    <FormGroup>
                        <label htmlFor="exampleInputname">Date Due For Retirement</label>
                        <Form.Control type="date" className="form-control"
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                        />
                    </FormGroup>
                    <Col className="mt-2">
                        <Button type="submit" className="btn btn-primary"> <span className="fa fa-search"></span> Search</Button>
                    </Col>

                    <Col>
                        <div className="">
                            <div className="">
                                <StaffDueForRetirement dueDate={dueDate} />
                            </div>
                        </div>
                    </Col>

                </Card.Body>
            </Card>
        </div>
    )
}

export default RetirementAlert