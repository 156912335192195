import React from 'react'
import { Breadcrumb, Col, Row, Card, Button } from "react-bootstrap";
import AdviceVariation from '../../../data/Variation/AdviceVariation';

export default function VariationAdvice() {
  return (
    <div>
      <div className="page-header ">
        <div>
          <h1 className="page-title">Variation Advice</h1>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item" href="#">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item active breadcrumds"
              aria-current="page"
            >
               Variation Advice
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
       
      </div>
      
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
                <Col className="card-title text-center mb-0"> 
                VARIATION ADVICE <br /> NATIONAL JUDICIARY COUNCIL
                </Col>
                
              {/* <div className="row justify-content-between">
              <div className="col-4">
                <p>Signature:................</p>
                <p><b>SENIOR ADMIN OFFICER</b></p>
                <p>Administration and Personnel</p>
                <p><i>for:</i> <b>SECRETARY</b></p>
              </div>
              <div className="col-4">
                <p>Date Received:............</p>
                <p><b>Date action Taken</b></p>
                <p>Signature:................</p>
              </div>
            </div> */}
            </Card.Header>
           
            <Card.Body>
              <div className="">
                <div className="">
                <AdviceVariation />
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
            <div className="row justify-content-between">
              <div className="col-4">
                <p>Signature:...............................</p>
                <p><b>SENIOR ADMIN OFFICER</b></p>
                <p>Administration and Personnel</p>
                <p><i>for:</i> <b>SECRETARY</b></p>
              </div>
              <div className="col-4">
                <p>Date Received:...........................</p>
                <p><b>Date action Taken</b></p>
                <p>Signature:...............................</p>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <hr />
            <div className="">
                <p>DIRECTOR <b>(Admin)</b></p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                Please .................................................................................................................................................... is due for his/her Annual <br></br>
                    increment with effect from ............................................................................................................................................................. <br></br>
                    you may wish to approve for further action
                </p>
            </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            <div className="row justify-content-between">
              <div className="col-4">
                
              </div>
              <div className="col-4">
                .........................................
                <br />
                <p>Mrs. Ayine Helen Tambu</p>
                <p>Assistant Director <b>(Admin).</b></p>
              </div>
            </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
