import React from 'react'
import { Breadcrumb, Col, Row, Card, Button } from "react-bootstrap";
import ApproveStaffIncrement from '../../../data/Variation/ApproveStaffIncrement'

const ApproveStaffVariation = () => {
    return (
        <div>
            <div className="page-header ">
                <div>
                    <h1 className="page-title">Approve Staff Variation </h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Approve Staff Variation
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>

            <Row>
                <Col sm={12} className="col-12">
                    <Card>
                        <Card.Header>
                            <Col className="card-title text-center mb-0"> APPROVE STAFF ANNUAL INCREMENT </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className="">
                                <div className="">
                                    <ApproveStaffIncrement />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ApproveStaffVariation