import React, { useState, useEffect } from 'react'
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import endpoint from "../../context/endpoint";
import { Context } from "../../context/Context";
import { CForm } from "@coreui/react";
import { DropdownButton, ButtonGroup, Card, Button, Row, Col, InputGroup, Dropdown, Modal, FormGroup, Form, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import Loader from "../Loader/loader";

const StaffDueForRetirement = ({dueDate}) => {
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState(false);

    useEffect(() => {
        getStaffsDueForRetirement();
    }, [])

    const getStaffsDueForRetirement = async() => {
        await endpoint.get(`/variation/retirement/alert`, {date: dueDate})
        .then((res) => {
            // console.log("response", res)
        }).catch((err) => {
            // console.log(err)
        })
    }

    const columns = [
        {
            name: "S/N",
            cell: (row, index) => (index + 1),
            width: "65px",
        },
        {
            name: "FILENO.",
            selector: (row) => [row.fileno],

            style: { textAlign: 'right' },
            sortable: true,

            width: "180px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">NJC/P/0987</div>
            // ,
        },
        {
            name: "FULLNAME",
            selector: (row) => [row.fullname],

            style: { textAlign: 'right' },
            sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: <div>DATE OF APPOINTMENT</div>,
            selector: (row) => [row.date_of_first_appointment],

            style: { textAlign: 'center' },
            sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
            name: <div>DATE OF PRESENT APPOINTMENT</div>,
            selector: (row) => [row.date_of_appointment],

            style: { textAlign: 'center' },
            sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        },
        {
            name: "STATUS",
            // selector: (row) => [row.date_of_appointment],

            style: { textAlign: 'center' },
            sortable: true,

            // width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">1/2/2012</div>
            // ,
        }

    ]

    const tableDatas = {
        columns,
        data,
    };

    return (
        <>
            {
                <DataTableExtensions {...tableDatas}>
                    {isLoading ? <Loader />
                        : <DataTable
                            fixedHeader
                            columns={columns}
                            // selectableRows
                            data={data}
                            // customStyles={customStyles}
                            persistTableHead
                            defaultSortField="id"
                            defaultSortAsc={false}
                            striped={true}
                            center={true}
                            pagination
                            paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangePage={handlePageChange}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                            // onChangeRowsPerPage(currentRowsPerPage, currentPage)
                            // paginationPerPage={perPage}
                            highlightOnHover
                        />
                    }

                </DataTableExtensions>
            }

        </>
    )
}

export default StaffDueForRetirement