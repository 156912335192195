import React, { useState, useEffect } from 'react'
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import endpoint from "../../context/endpoint";
import { Context } from "../../context/Context";
import { CForm } from "@coreui/react";
import { DropdownButton, ButtonGroup, Card, Button, Row, Col, InputGroup, Dropdown, Modal, FormGroup, Form, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import Loader from "../Loader/loader";
import { Link } from "react-router-dom";

const ApproveStaffIncrement = () => {
    const [isLoading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [showAdd, setAdd] = useState(false);
    const [showReject, setReject] = useState(false);
    const [showReverse, setReverse] = useState(false);
    const [designations, setDesignations] = useState([])

    useEffect(() => {
        getDesignations()
    }, [])

    const getDesignations = async () => {
        await endpoint.get(`/designation/show`)
            .then((res) => {
                // console.log("desig", res.data.data)
                setDesignations(res.data.data)
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    const columns = [
        {
            name: "S/N",
            cell: (row, index) => (index + 1),
            width: "65px",
        },
        {
            name: "FILENO.",
            selector: (row) => [row.fileno],

            style: { textAlign: 'right' },
            sortable: true,

            width: "120px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">NJC/P/0987</div>
            // ,
        },
        {
            name: "FULLNAME",
            selector: (row) => [row.fullname],

            style: { textAlign: 'right' },
            sortable: true,

            width: "200px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: "GRADE",
            selector: (row) => [row.grade],

            style: { textAlign: 'right' },
            sortable: true,

            width: "100px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: "STEP",
            selector: (row) => [row.step],

            style: { textAlign: 'right' },
            sortable: true,

            width: "100px",
            // cell: (row) =>
            //     <div className="fs-12 fw-bold ">Mr. ABIDJAN JUACHIN Moj</div>
            // ,
        },
        {
            name: <div>VARIATION LETTER</div>,
            selector: (row) => [row],

            style: { textAlign: 'center' },
            // sortable: true,

            width: "150px",
            cell: (row) =>
                <div className="">
                    <Link to="#"
                        className="btn btn-indigo btn-sm my-1"
                        variant="indigo"
                    >
                        <span className="fe fe-eye"> View Variation Letter </span>
                    </Link>
                </div>
            ,
        },
        {
            name: "ACTION",
            selector: (row) => [],
            // style: { textAlign: '' },
            width: "200px",
            cell: (row) => (
                <div className="">
                    <button
                        className="btn btn-danger btn-sm my-1"
                        variant="danger"
                        onClick={() => setReject(true)}
                    >
                        <span className="fe fe-rewind"> Reject </span>
                    </button>
                    <button
                        className="btn btn-success btn-sm my-1"
                        variant="success"
                        onClick={() => setAdd(true)}
                    >
                        <span className="fe fe-fast-forward"> Approve </span>
                    </button>
                </div>
            )
        },
        {
            name: "REVERSE",
            selector: (row) => [],
            // style: { textAlign: '' },
            width: "200px",
            cell: (row) => (
                <div className="">
                    <button
                        className="btn btn-warning btn-sm my-1"
                        variant="warning"
                        onClick={() => setReverse(true)}
                    >
                        <span className="fe fe-rewind"> Reverse </span>
                    </button>
                </div>
            )
        }

    ]

    const data = [
        {
            id: 1,
            fileno: "NJC/P/0987",
            fullname: "Mr. ABIDJAN JUACHIN Moj",
            grade: 15,
            step: 8,

        },
        {
            id: 1,
            fileno: "NJC/P/1027",
            fullname: "Mr. AHMNED ALMANI JIFFA",
            grade: 13,
            step: 5,

        }
    ];

    const tableDatas = {
        columns,
        data,
    };
    return (
        <>
            {
                <DataTableExtensions {...tableDatas}>
                    {isLoading ? <Loader />
                        : <DataTable
                            fixedHeader
                            columns={columns}
                            // selectableRows
                            data={data}
                            // customStyles={customStyles}
                            persistTableHead
                            defaultSortField="id"
                            defaultSortAsc={false}
                            striped={true}
                            center={true}
                            pagination
                            paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangePage={handlePageChange}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                            // onChangeRowsPerPage(currentRowsPerPage, currentPage)
                            // paginationPerPage={perPage}
                            highlightOnHover
                        />
                    }

                </DataTableExtensions>
            }

            {/* modal to confirm staff */}
            <Modal show={showAdd} >
                <Modal.Header >
                    <Button
                        onClick={() => setAdd(false)}
                        className="btn-close"
                        variant=""
                    >
                        x
                    </Button>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h3"> Approve Staff Increment </Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <Col lg={12} md={12}>
                                        <FormGroup>
                                            <label htmlFor="exampleInputname">Comment</label>
                                            <textarea
                                                className="form-control is-invalid"
                                                id="validationTextarea"
                                                placeholder="Enter reason for approval"
                                                required
                                            ></textarea>
                                        </FormGroup>
                                    </Col>

                                </Card.Body>
                            </Card>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className="me-1" onClick={() => setAdd(false)}>
                            Close
                        </Button>
                        <Button type="button" variant="success" onClick={() => setAdd(false)} className="me-1">
                            Confirm
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* end modal to confirm staff*/}

            {/* modal to reject staff Increment */}
            <Modal show={showReject} >
                <Modal.Header >
                    <Button
                        onClick={() => setReject(false)}
                        className="btn-close"
                        variant=""
                    >
                        x
                    </Button>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h3"> Reject Staff Increment </Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <Col lg={12} md={12}>
                                        <FormGroup>
                                            <label htmlFor="exampleInputname">Comment</label>
                                            <textarea
                                                className="form-control is-invalid"
                                                id="validationTextarea"
                                                placeholder="Enter reason for Rejection"
                                                required
                                            ></textarea>
                                        </FormGroup>
                                    </Col>

                                </Card.Body>
                            </Card>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className="me-1" onClick={() => setReject(false)}>
                            Close
                        </Button>
                        <Button type="button" variant="success" onClick={() => setReject(false)} className="me-1">
                            Confirm
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* end modal to reject staff Increment*/}

            {/* modal to reverse staff Increment */}
            <Modal show={showReverse} >
                <Modal.Header >
                    <Button
                        onClick={() => setReverse(false)}
                        className="btn-close"
                        variant=""
                    >
                        x
                    </Button>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h3"> Reverse Staff Increment </Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <Col lg={12} md={12}>
                                        <FormGroup>
                                            <label htmlFor="exampleInputname">Comment</label>
                                            <textarea
                                                className="form-control is-invalid"
                                                id="validationTextarea"
                                                placeholder="Enter reason for Staff Increment reversal"
                                                required
                                            ></textarea>
                                        </FormGroup>
                                    </Col>

                                </Card.Body>
                            </Card>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className="me-1" onClick={() => setReverse(false)}>
                            Close
                        </Button>
                        <Button type="button" variant="success" onClick={() => setReverse(false)} className="me-1">
                            Confirm
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* end modal to reverse staff Increment*/}
        </>
    )
}

export default ApproveStaffIncrement